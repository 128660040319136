import React from 'react';

import { css } from '@emotion/css';
import { fileSize } from 'humanize-plus';
import { keys as _keys, round as _round } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => ({
  columnValues: css({
    color: theme.colors.text.secondary,
  }),
  detailColumn: css({
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  }),
  expandSession: css({
    display: 'flex',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(15)}`,
    maxWidth: '50%',
  }),
  infoIcon: css({
    marginLeft: theme.spacing(1),
  }),
  serviceNameWrapper: css({
    marginLeft: theme.spacing(5),
  }),
});

export const RecommendationBreakDown = ({ recommendation }: Props) => {
  const { attribution } = recommendation;
  const styles = useStyles2(getStyles);
  const serviceNames = _keys(attribution);

  return (
    <div className={styles.serviceNameWrapper} data-testid="recommendation-break-down">
      <h3>Pattern breakdown by service</h3>
      <div className={styles.expandSession}>
        <div className={styles.detailColumn}>
          <div>
            Service name
            <Tooltip content={'Pattern generated from these identified services.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>
          {serviceNames.map((service) => {
            const name = service.substring(service.indexOf('"') + 1, service.lastIndexOf('"'));
            return (
              <div key={name} className={styles.columnValues}>
                {name}
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Percentage
            <Tooltip content={'Percentage of total volume per service.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>
          {serviceNames.map((service) => {
            const { Volume: volume } = attribution[service];
            const { volume: totalVolume } = recommendation;
            const percentage = (volume / totalVolume) * 100;
            const roundedPercentage = _round(percentage, 2);

            return (
              <div key={service} data-testid={`${service}-percentage`} className={styles.columnValues}>
                {roundedPercentage === 0 && percentage > 0 ? '<1' : roundedPercentage}%
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Savings
            <Tooltip content={'The projected savings over 15 days with the configured drop rage.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>

          {serviceNames.map((serviceName) => {
            const { Volume: volume } = attribution[serviceName];
            const savings = volume * (recommendation.configured_drop_rate / 100);
            return (
              <div className={styles.columnValues} key={serviceName} data-testid={`${serviceName}-savings`}>
                {fileSize(savings, 1)}
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Volume
            <Tooltip content={'The ingested volume over the last 15 days.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>

          {serviceNames.map((serviceName) => {
            const { Volume: volume } = attribution[serviceName];
            return (
              <div className={styles.columnValues} key={serviceName} data-testid={`${serviceName}-volume`}>
                {fileSize(volume, 1)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { debounce as _debounce, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';

import { SelectableValue } from '@grafana/data';

import { PatternRecommendation } from '@/api/types';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { ModifiedDropRate, ModifiedDropRateMap } from '@/types/modified';
import { filterRecommendations } from '@/utils/filter';
import { triggerToast } from '@/utils/grafana';

type AdaptiveLogsContextType = {
  applyDropRateModifications: () => Promise<void>;
  canApplyModifications: boolean;
  canRevertModifications: boolean;
  filter: string;
  filterCheck: FilterCheck;
  modifiedDropRates: Map<string, ModifiedDropRate>;
  modifiedPatternConfigCount: number;
  recommendationsUpdating: boolean;
  revertUnlockedDropRates: () => void;
  serviceNameFilter?: Array<SelectableValue<string>>;
  setDropRate: (key: string, rate: string, forceLock?: boolean) => void;
  setDropRateLocked: (key: string, locked: boolean) => void;
  setFilter: (filter: string) => void;
  setMaxDropRate: (maxRate: number) => void;
  setServiceNameFilter: (serviceNames: Array<SelectableValue<string>>) => void;
};

export const AdaptiveLogsContext = createContext<AdaptiveLogsContextType>({} as AdaptiveLogsContextType);

interface Props {
  children: React.ReactNode;
}

export const AdaptiveLogsContextProvider = ({ children }: Props) => {
  const [filter, setFilter] = useState<string>('');
  const [serviceNameFilter, setServiceNameFilter] = useState<Array<SelectableValue<string>>>([]);

  const filterCheck = useFilterCheck(filter, serviceNameFilter);

  const {
    applyDropRateModifications,
    canApplyModifications,
    canRevertModifications,
    modifiedDropRates,
    modifiedPatternConfigCount,
    recommendationsUpdating,
    revertUnlockedDropRates,
    setDropRate,
    setDropRateLocked,
    setMaxDropRate,
  } = useModifiedDropRateState(filterCheck);

  return (
    <AdaptiveLogsContext.Provider
      value={{
        applyDropRateModifications,
        canApplyModifications,
        canRevertModifications,
        filter,
        filterCheck,
        modifiedDropRates,
        modifiedPatternConfigCount,
        recommendationsUpdating,
        revertUnlockedDropRates,
        serviceNameFilter,
        setDropRate,
        setDropRateLocked,
        setFilter,
        setMaxDropRate,
        setServiceNameFilter,
      }}
    >
      {children}
    </AdaptiveLogsContext.Provider>
  );
};

/**
 * Mimic the used api for Set.
 * If there is no filter this will be null
 */
type FilterCheck = {
  has: (pattern: string) => boolean;
  size: number;
} | null;

/**
 * @param filter set of patterns (keys) which should be omitted from the list
 */
function useFilterCheck(filter: string, serviceNameFilter: Array<SelectableValue<string>>): FilterCheck | null {
  const recommendations = useRecommendations();
  const items = recommendations.data?.items;

  const filterCheck = useMemo(() => {
    const serviceNameFilterSet = new Set(serviceNameFilter?.map((serviceName) => serviceName.value || ''));
    const acceptedIndices = filterRecommendations(items, filter, serviceNameFilterSet);

    // TODO, these indices could be re-used as a performance boost in ufuzzy in certain cases
    if (!items || acceptedIndices === null) {
      // Nothing is filtered out
      return null;
    }

    const filteredIn = new Set(acceptedIndices.map((index) => items[index].pattern));

    return filteredIn;
  }, [serviceNameFilter, items, filter]);

  return filterCheck;
}

function useModifiedDropRateState(filterCheck: FilterCheck) {
  const recommendations = useRecommendations();

  const { isPending: recommendationsUpdating, mutateAsync: saveRecommendations } = useUpdateRecommendationsMutation();
  const [canApplyModifications, setCanApplyModifications] = useState(true);
  const [canRevertModifications, setCanRevertModifications] = useState(true);
  const [modifiedPatternConfigCount, setModifiedPatternConfigCount] = useState(0);
  const [modifiedDropRates, setModifiedDropRates] = useState<ModifiedDropRateMap>(new Map());

  const mappedRecommendations = recommendations.data?.mappedItems;

  useEffect(() => {
    let badRates = 0;
    let changedPatternConfigCount = 0;

    if (mappedRecommendations === undefined) {
      setCanApplyModifications(false);
      setCanRevertModifications(false);
      setModifiedPatternConfigCount(0);
      return;
    }

    for (const [key, modified] of modifiedDropRates.entries()) {
      let rateIsChanged = false;
      let lockIsChanged = false;

      const savedRecommendation = mappedRecommendations.get(key);

      if (modified.rate !== undefined) {
        const rate = Number(modified.rate);
        if (rate < 0 || rate > 100) {
          badRates++;
        }

        const savedRate = savedRecommendation?.configured_drop_rate;

        rateIsChanged = savedRate !== rate;
      }

      if (modified.locked !== undefined) {
        const savedLockedState = savedRecommendation?.locked;

        lockIsChanged = savedLockedState !== modified.locked;
      }

      if (rateIsChanged || lockIsChanged) {
        changedPatternConfigCount++;
      }
    }

    // We only consider actual different rate values to be modifications
    const modificationsExist = changedPatternConfigCount > 0;
    setCanRevertModifications(modificationsExist);
    setCanApplyModifications(modificationsExist && badRates === 0);
    setModifiedPatternConfigCount(changedPatternConfigCount);
  }, [modifiedDropRates, setCanRevertModifications, setCanApplyModifications, mappedRecommendations]);

  const revertUnlockedDropRates = useCallback(() => {
    const lockedModifiedEntries = Array.from(modifiedDropRates.entries()).filter(([_, dropRate]) => dropRate.locked);
    setModifiedDropRates(new Map(lockedModifiedEntries));
  }, [setModifiedDropRates, modifiedDropRates]);

  const setDropRate = (key: string, rate: string, forceLock?: boolean) => {
    const locked = forceLock !== undefined ? forceLock : modifiedDropRates.get(key)?.locked;

    const savedRecommendation = mappedRecommendations?.get(key);
    const lockedUnchanged = locked === undefined || locked === savedRecommendation?.locked;
    const rateUnchanged = rate.trim() === '' || Number(rate) === savedRecommendation?.configured_drop_rate;

    if (lockedUnchanged && rateUnchanged) {
      // Remove the modified record
      modifiedDropRates.delete(key);
      setModifiedDropRates(new Map([...modifiedDropRates]));
    } else {
      setModifiedDropRates(new Map([...modifiedDropRates, [key, { locked, rate }]]));
    }
  };

  const setDropRateLocked = (key: string, locked: boolean) => {
    const rate = modifiedDropRates.get(key)?.rate; // Ok if undefined
    setModifiedDropRates(new Map([...modifiedDropRates, [key, { locked, rate }]]));
  };

  const setMaxDropRate = (maxRate: number) => {
    const { data, isFetched, isFetching } = recommendations;
    if (!isFetched || isFetching || !data) {
      throw new Error('Cannot set max drop rate while still fetching data.');
    }

    const newModifiedDropRates: ModifiedDropRateMap = new Map(modifiedDropRates);

    data.items.forEach((recommendation) => {
      const key = recommendation.pattern;

      if (filterCheck && !filterCheck.has(key)) {
        // If this pattern isn't part of the filter check, we change nothing
        return;
      }

      const currentModification = modifiedDropRates.get(key);

      const modifiedToUnlock = currentModification?.locked === false;
      const locked = currentModification?.locked || (recommendation.locked && !modifiedToUnlock);

      if (locked) {
        // For locked rates, we change nothing.
        return;
      }

      const rate = recommendation.recommended_drop_rate > maxRate ? maxRate : recommendation.recommended_drop_rate;

      newModifiedDropRates.set(key, { locked: false, rate: `${rate}` });
    });

    setModifiedDropRates(newModifiedDropRates);
  };

  const applyDropRateModifications = async () => {
    // TODO: better way to check these, prevent from happening
    if (recommendations.isError) {
      throw new Error(
        "Can't save recommendations because the original recommendations haven't been successfully fetched."
      );
    }
    if (!recommendations.isFetched) {
      throw new Error("Can't save recommendations because the original recommendations haven't been fetched yet.");
    }
    if (recommendations.isFetching) {
      throw new Error(
        "Can't save recommendations because the original recommendations haven't are still being fetched."
      );
    }
    if (!recommendations.data?.items) {
      throw new Error("Can't save recommendations because the original recommendations aren't formed into a list.");
    }

    let canApplyModifications = true;
    const newRecs = recommendations.data.items.map((rec: PatternRecommendation) => {
      const key = rec.pattern;
      const modified = modifiedDropRates.get(key);
      if (!modified) {
        // Unchanged
        return rec;
      }

      const value = modified.rate === undefined ? rec.configured_drop_rate : Number(modified.rate);

      if (value > 100 || value < 0) {
        // TODO collect info on which row had a bad range to show user (unless we already have a soln)
        canApplyModifications = false;
      }

      const locked = modified?.locked === undefined ? rec.locked : modified.locked;

      return { ...rec, configured_drop_rate: value, locked } as PatternRecommendation;
    });

    // NOTE: Added this, to prevent saving if we found an issue
    if (!canApplyModifications) {
      triggerToast('alertError', 'Could not apply drop rates');
    }

    await saveRecommendations(newRecs);

    triggerToast('alertSuccess', 'Drop rates saved');
    setModifiedDropRates(new Map());
  };

  return {
    applyDropRateModifications,
    canApplyModifications,
    canRevertModifications,
    modifiedDropRates,
    modifiedPatternConfigCount,
    recommendationsUpdating,
    revertUnlockedDropRates,
    setDropRate,
    setDropRateLocked,
    setMaxDropRate,
  };
}

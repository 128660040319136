import { isEqual as _isEqual } from 'lodash';

import { AppEvents, OrgRole } from '@grafana/data';
import { config, getAppEvents } from '@grafana/runtime';

import { PluginPermissions } from '@/types';

const isUserActionAllowed = (permission: PluginPermissions, fallbackOrgRole: OrgRole) => {
  const { orgRole: userOrgRole, permissions: userPermissions } = config.bootData.user;
  return config.featureToggles.accessControlOnCall
    ? !!userPermissions?.[permission]
    : _isEqual(userOrgRole, fallbackOrgRole);
};

export const getUserPermissions = () => {
  const { permissions } = config.bootData.user;

  return {
    canAccessExplore: !!permissions?.['datasources:explore'],
    canAccessPlugin: isUserActionAllowed('grafana-adaptivelogs-app.plugin:access', OrgRole.Admin),
    canApplyPatterns: isUserActionAllowed('grafana-adaptivelogs-app.patterns:apply', OrgRole.Admin),
    canReadPatterns: isUserActionAllowed('"grafana-adaptivelogs-app.patterns:read', OrgRole.Admin),
    hasPermission: (permission: PluginPermissions) => isUserActionAllowed(permission, OrgRole.Admin),
  };
};

type ToastType = 'alertError' | 'alertSuccess' | 'alertWarning';

export const triggerToast = (type: ToastType, message: string) => {
  const alertType = AppEvents[type];

  const payload = [message];
  // There are two additional strings on the payload which we are not using

  getAppEvents().publish({
    payload,
    type: alertType.name,
  });
};

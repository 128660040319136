import { useContext, useMemo } from 'react';

import { some as _some, values as _values } from 'lodash';

import { AdaptiveLogsContext } from '@/context/adaptive-logs-context';
import { getUserPermissions } from '@/utils/grafana';

export const useFilter = () => {
  const { filter, filterCheck, setFilter } = useContext(AdaptiveLogsContext);

  const isFiltered = useMemo(() => {
    if (filter) {
      return true;
    } else {
      return false;
    }
  }, [filter]);

  if (filter === undefined) {
    throw new Error('useFilter must be used within AdaptiveLogsContextProvider');
  }

  return {
    filter,
    filterCheck,
    isFiltered,
    setFilter,
  };
};

// TODO we can probably include this in the useFilter
export const useServiceNameFilter = () => {
  const { serviceNameFilter, setServiceNameFilter } = useContext(AdaptiveLogsContext);

  if (serviceNameFilter === undefined) {
    throw new Error('useServiceNameFilter must be used within AdaptiveLogsContextProvider');
  }

  return {
    serviceNameFilter,
    setServiceNameFilter,
  };
};

export const useModifiedDropRates = () => {
  const {
    applyDropRateModifications,
    canApplyModifications,
    canRevertModifications,
    modifiedDropRates,
    modifiedPatternConfigCount,
    recommendationsUpdating,
    revertUnlockedDropRates,
    setDropRate,
    setDropRateLocked,
    setMaxDropRate,
  } = useContext(AdaptiveLogsContext);

  if (!modifiedDropRates) {
    throw new Error('useModifiedDropRates must be used within AdaptiveLogsContextProvider');
  }

  return {
    applyDropRateModifications,
    canApplyModifications,
    canRevertModifications,
    modifiedDropRates,
    modifiedPatternConfigCount,
    recommendationsUpdating,
    revertUnlockedDropRates,
    setDropRate,
    setDropRateLocked,
    setMaxDropRate,
  };
};

export const useUserPermissions = () => getUserPermissions();

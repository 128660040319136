import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { omit as _omit } from 'lodash';

import { GrafanaTheme2, IconName } from '@grafana/data';
import { IconButton, InlineField, Input, PopoverContent, Tooltip, useStyles2 } from '@grafana/ui';

import { TablePatternRecommendation } from '../RecommendationsTable/types';
import { useModifiedDropRates } from '@/hooks/context-hooks';
import { getComparisonWithRecommendation } from '@/types/style';

interface Props {
  recommendation: TablePatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      display: 'flex',
    }),
    inputBoxModified: {
      default: css({
        alignItems: 'center',
        border: `1px solid ${theme.colors.success.border}`,
      }),
      recommendedIsLess: css({
        alignItems: 'center',
        border: `1px solid ${theme.colors.warning.border}`,
      }),
      // If the input box is modified, but in an error state,
      // the browser will indicate via the `invalid` property on the inline field.
    },

    lockField: css({
      lineHeight: 2.5,
    }),
    lockIcon: css({
      color: 'rgba(194, 109, 49, 1)',
    }),
    numberInput: css({
      marginBottom: 0,
    }),
  };
};

export const CurrentRateEditor = ({ recommendation }: Props) => {
  const styles = useStyles2(getStyles);
  const { modifiedDropRates, setDropRate, setDropRateLocked } = useModifiedDropRates();
  // This keeps track if the lock has been clicked on, in case it clashes with the 'onBlur' event
  const [mouseDownOnLock, setMouseDownOnLock] = useState(false);
  const {
    configured_drop_rate: savedRate,
    locked: savedAsLocked,
    pattern: key,
    recommended_drop_rate: recommendedRate,
  } = recommendation;

  const modified = modifiedDropRates.get(key);

  const displayedRate = modified?.rate || savedRate;
  const modifiedRateAsNumber = Number(modified?.rate);

  const locked = modified?.locked === undefined ? savedAsLocked : modified.locked;

  const rateIsModified = modified?.rate && savedRate !== modifiedRateAsNumber;
  const displayedRateAsNumber = Number(displayedRate);

  const rateOutOfRange = displayedRateAsNumber > 100 || displayedRateAsNumber < 0;

  const inputClassKey = useMemo(() => {
    const comparison = getComparisonWithRecommendation(recommendedRate, modifiedRateAsNumber);
    if (!comparison) {
      return undefined;
    }
    if (comparison !== 'recommendedIsLess') {
      return 'default';
    }
    return comparison;
  }, [recommendedRate, modifiedRateAsNumber]);

  const inputClassName = inputClassKey ? styles.inputBoxModified[inputClassKey] : undefined;
  const icon: IconName = locked ? 'lock' : 'unlock';

  const currentRateComponents = (
    <div className={styles.container}>
      <InlineField
        className={styles.numberInput}
        invalid={rateOutOfRange}
        error={'Drop percentage must be between 0 and 100%'}
        disabled={locked}
      >
        <Input
          data-testid="configured-drop-rate"
          className={inputClassName}
          maxLength={3}
          defaultValue={displayedRate}
          width={8}
          type="number"
          onBlur={(e) => {
            if (mouseDownOnLock) {
              // The blur event was caused by clicking on the lock
              // So we need to make sure we activate the response for that action
              // otherwise setModifiedRecommendations will cause the response to be cancelled
              const forceLock = !locked;
              setDropRate(key, e.currentTarget.value, forceLock);
              // This is not a perfect solution, and may need some improvements for more mouse cases.
            } else {
              setDropRate(key, e.currentTarget.value);
            }
          }}
        />
      </InlineField>
      <InlineField className={styles.lockField}>
        <IconButton
          className={locked ? styles.lockIcon : ''}
          data-testid={`icon-${icon}`}
          aria-label="pattern-lock"
          name={icon}
          onClick={() => setDropRateLocked(key, !locked)}
          onMouseDown={() => setMouseDownOnLock(true)}
          onMouseUp={() => setMouseDownOnLock(false)}
          size="lg"
        />
      </InlineField>
    </div>
  );

  if (rateOutOfRange) {
    // Do not bother with tooltips if we have a visible error state.
    return currentRateComponents;
  }

  const tooltipContent: PopoverContent = () => {
    const activeRate = savedAsLocked ? (
      <div>{`This pattern's drop rate is saved at ${savedRate}% and locked.`}</div>
    ) : (
      <div>{`This pattern's drop rate is saved at ${savedRate}%.`}</div>
    );

    if (!rateIsModified) {
      if (modified?.locked === undefined) {
        return activeRate;
      }

      const lockMessage = modified.locked
        ? 'Applying this change will lock the rate at that value.'
        : 'Applying this change will unlock the rate so it can be changed.';

      return (
        <>
          {activeRate}
          <div>{lockMessage}</div>
        </>
      );
    }

    if (modified.locked) {
      return (
        <>
          {activeRate}
          <div>This will change the drop rate to {modifiedRateAsNumber}% and lock it at that value.</div>
        </>
      );
    }

    return (
      <>
        {activeRate}
        <div>This will change the drop rate to {modifiedRateAsNumber}%.</div>
      </>
    );
  };

  return <Tooltip content={tooltipContent}>{currentRateComponents}</Tooltip>;
};

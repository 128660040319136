import { useMemo } from 'react';

import { useRecommendations } from '@/hooks/api-hooks';
import { useFilter } from '@/hooks/context-hooks';

type ReccomendationFilterCounts = {
  recommendationsFiltered?: number;
  recommendationsLoaded?: number;
};

export function useRecommendationFilterCounts(): ReccomendationFilterCounts {
  const { filterCheck } = useFilter();

  const { data } = useRecommendations();

  return {
    recommendationsFiltered: filterCheck?.size,
    recommendationsLoaded: data?.items.length,
  };
}

export function useRecommendationFilterCountMessage() {
  const { recommendationsFiltered, recommendationsLoaded } = useRecommendationFilterCounts();

  const filterCountMessage = useMemo(() => {
    if (!recommendationsLoaded) {
      return null;
    }

    if (recommendationsFiltered === undefined) {
      return `Showing ${recommendationsLoaded} patterns`;
    }

    return `Showing ${recommendationsFiltered} of ${recommendationsLoaded} patterns`;
  }, [recommendationsFiltered, recommendationsLoaded]);

  return filterCountMessage;
}

import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { fileSize } from 'humanize-plus';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '../../api/types';
import { getAbsoluteTotalVolume, getSummaryVolumes } from './utils';
import { useMetrics } from '@/hooks/api-hooks';
import { useModifiedDropRates } from '@/hooks/context-hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  recommendations: PatternRecommendation[];
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    content: css({
      textAlign: 'center',
    }),
    info: css({
      paddingTop: theme.spacing(4),
      textAlign: 'center',
    }),
  };
};

export function SummaryModal(props: Props) {
  const styles = useStyles2(getStyles);
  const { modifiedDropRates } = useModifiedDropRates();
  const { data: metrics } = useMetrics();

  const [relativeTotalVolume, relativeAfterDrop] = useMemo(() => {
    const recommendationToSummary: PatternRecommendation[] = props.recommendations.map((recommendation) => {
      if (modifiedDropRates.has(recommendation.pattern) && modifiedDropRates.get(recommendation.pattern)?.rate) {
        const updatedRate = modifiedDropRates.get(recommendation.pattern)?.rate;

        if (!updatedRate) {
          return recommendation;
        }

        return { ...recommendation, configured_drop_rate: +updatedRate };
      } else {
        return recommendation;
      }
    });

    return getSummaryVolumes(recommendationToSummary);
  }, [modifiedDropRates, props.recommendations]);

  const absoluteTotalVolume = getAbsoluteTotalVolume(metrics);
  const projectedSavings = relativeTotalVolume - relativeAfterDrop;

  const beforeDrops =
    absoluteTotalVolume !== null ? fileSize(absoluteTotalVolume, 2) : fileSize(relativeTotalVolume, 2);
  const afterDrops =
    absoluteTotalVolume !== null ? fileSize(absoluteTotalVolume - projectedSavings, 2) : fileSize(relativeAfterDrop, 2);

  return (
    <Modal title="Savings preview" isOpen={props.isOpen} onDismiss={() => props.onClose()}>
      <div className={styles.content}>
        This is the projected impact of the drop rates you have selected based on ingestion over the last 15 days.
      </div>
      <div className={styles.info}>
        <div>Before drops: {beforeDrops}</div>
        {/** ingested_volume + dropped_volume  -> absolute total*/}
        <div>After drops: {afterDrops}</div>
        {/** absolute total - projected savings */}
        <div>Projected savings: {fileSize(projectedSavings)}</div> {/** relative total - relative drop value */}
      </div>
      <Modal.ButtonRow>
        <Button variant="secondary" onClick={() => props.onClose()}>
          Close
        </Button>
      </Modal.ButtonRow>
    </Modal>
  );
}

export type RecommendationComparison = 'recommendedIsEqual' | 'recommendedIsGreater' | 'recommendedIsLess';

export function getComparisonWithRecommendation(
  recommendedRate: number,
  currentRate: number
): RecommendationComparison | undefined {
  if (Number.isNaN(currentRate) || currentRate < 0 || currentRate > 100) {
    return undefined;
  } else if (recommendedRate < currentRate) {
    return 'recommendedIsLess';
  } else if (recommendedRate > currentRate) {
    return 'recommendedIsGreater';
  } else {
    return 'recommendedIsEqual'; // This rate is "Just right."
  }
}

import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { flatten as _flatten } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, InlineField, InlineFieldRow, Input, MultiSelect, useStyles2 } from '@grafana/ui';

import { PatternModifiedCount } from './PatternModifiedCount';
import { ApplyModal } from '@/components/ApplyModal';
import { ContentBox } from '@/components/ContentBox';
import { SummaryModal } from '@/components/SummaryModal';
import { useRecommendations } from '@/hooks/api-hooks';
import { useModifiedDropRates, useServiceNameFilter, useUserPermissions } from '@/hooks/context-hooks';

export const DIVISIONS = 5;
export const LARGE_SPAN = Math.floor(2 * (DIVISIONS / 3));
const SERVICE_NAME_FILTER_WIDTH = 50;

const getStyles = (theme: GrafanaTheme2, canApplyPatterns: boolean) => {
  return {
    base: css({
      display: 'grid',
      gridTemplateColumns: '3fr 1fr',
      marginTop: theme.spacing(1),
    }),
    // TODO: hack to fix the error message pushing other components around. The`validationMessageHorizontalOverflow`
    // property is supposed to cover this for us but doesn't seem to do anything. There's probably a better way to
    // do this
    errorFix: css`
      div[role='alert'] {
        position: fixed;
      }
    `,
    flexRowContent: css({
      alignItems: 'flex-end',
      display: 'flex',
      gap: theme.spacing(1),
      justifyContent: 'flex-end',
      marginTop: canApplyPatterns ? theme.spacing(5) : undefined,
    }),
    maxDropRate: css({
      display: 'grid',
      gridGap: theme.spacing(1),
    }),
    numberInput: css({
      input: {
        textAlign: 'right',
      },
    }),
    serviceNameFilterField: css({
      marginBottom: 0,
      marginTop: canApplyPatterns ? theme.spacing(5) : undefined,
    }),
  };
};

export const PageHeader = () => {
  const [summaryOpen, setSummaryOpen] = useState(false);
  const [applyOpen, setApplyOpen] = useState(false);
  // Will be `undefined` if the field is left blank
  const [maxRate, setMaxRate] = useState<number | undefined>(100);
  const userPermissions = useUserPermissions();

  const { serviceNameFilter, setServiceNameFilter } = useServiceNameFilter();
  const { data: recommendations, isFetching: recommendationsIsFetching } = useRecommendations();
  const attributions = useMemo(
    () => recommendations?.items.map((item) => Object.keys(item.attribution)),
    [recommendations?.items]
  );
  const serviceNames = useMemo(
    () =>
      [...new Set(_flatten(attributions))].map((attribution) => {
        const name = attribution.substring(attribution.indexOf('"') + 1, attribution.lastIndexOf('"'));
        return {
          label: name,
          text: name,
          value: name,
        };
      }),
    [attributions]
  );

  // Note that these are only the saved recommendations, and do not include contributions from
  // the modified rates... We may want to include these in the calculations as well.
  const recommendationsForSummary = recommendations?.items || [];
  const { canApplyModifications, canRevertModifications, revertUnlockedDropRates, setMaxDropRate } =
    useModifiedDropRates();

  const styles = useStyles2(getStyles, userPermissions.canApplyPatterns);

  const maxRateInvalid = maxRate === undefined || maxRate < 0 || maxRate > 100;

  const applyMaxDropRate = () => {
    if (!maxRateInvalid) {
      setMaxDropRate(maxRate);
    }
  };

  return (
    <ContentBox className={styles.base}>
      <div className={styles.maxDropRate}>
        {userPermissions.canApplyPatterns && (
          <InlineFieldRow>
            <InlineField
              className={styles.errorFix}
              invalid={maxRateInvalid}
              error={'Drop percentage must be between 0% and 100%'}
              label="Maximum drop"
              tooltip="Any pattern with a drop % greater than this will be changed to this value"
            >
              <Input
                data-testid="maximum-rate"
                className={styles.numberInput}
                defaultValue={maxRate}
                maxLength={3}
                width={8}
                type="number"
                suffix={'%'}
                onInput={(event) => {
                  const value = event.currentTarget.value.trim();
                  if (value === '') {
                    setMaxRate(undefined);
                  } else {
                    setMaxRate(Number(value));
                  }
                }}
                onKeyDown={(e) => !maxRateInvalid && e.key === 'Enter' && applyMaxDropRate()}
              />
            </InlineField>
            <Button fill={'outline'} onClick={applyMaxDropRate} disabled={maxRateInvalid}>
              Set
            </Button>
          </InlineFieldRow>
        )}
        <InlineField className={styles.serviceNameFilterField} label="Filter by service">
          <MultiSelect
            data-testid="service-filter"
            isSearchable
            isClearable
            width={SERVICE_NAME_FILTER_WIDTH}
            options={serviceNames}
            value={serviceNameFilter}
            placeholder="All services"
            onChange={(v) => {
              setServiceNameFilter(v);
            }}
          />
        </InlineField>
      </div>

      <div className={styles.flexRowContent}>
        <PatternModifiedCount />
        <Button variant="secondary" onClick={() => setSummaryOpen(true)}>
          Preview
        </Button>
        {userPermissions.canApplyPatterns && (
          <>
            <Button
              variant="secondary"
              disabled={!canRevertModifications}
              onClick={() => {
                revertUnlockedDropRates();
              }}
              tooltip={
                'This will restore all values to their previously saved values (except where they have been locked).'
              }
            >
              Revert
            </Button>
            <Button disabled={!canApplyModifications} onClick={() => setApplyOpen(true)}>
              Apply drop rates
            </Button>
          </>
        )}
      </div>

      <SummaryModal
        recommendations={recommendationsForSummary}
        isOpen={summaryOpen}
        onClose={() => setSummaryOpen(false)}
      />
      <ApplyModal
        isOpen={applyOpen}
        onClose={() => {
          setApplyOpen(false);
        }}
        recommendationsIsFetching={recommendationsIsFetching}
      />
    </ContentBox>
  );
};

import { AppPlugin } from '@grafana/data';

import { App } from './pages/App';
import { AppPluginSettings } from './pages/AppConfig';
import { getUserPermissions } from './utils/grafana';
import { NoAccess } from '@/pages/NoAccess';

if (process.env.NODE_ENV === 'development' && process.env.ENABLE_MSW) {
  const { worker } = require('./_mocks_/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
    serviceWorker: {
      options: {
        scope: '/a/',
      },
      url: '/public/msw/mockServiceWorker.js',
    },
  });
}

export const plugin = new AppPlugin<AppPluginSettings>();

const userPermissions = getUserPermissions();

if (userPermissions.canAccessPlugin) {
  plugin.setRootPage(App);
} else {
  plugin.setRootPage(NoAccess);
}

import React, { ComponentProps, useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { fileSize, formatNumber } from 'humanize-plus';
import { debounce as _debounce, flatten as _flatten, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { CellProps, Column, Icon, InteractiveTable, useStyles2 } from '@grafana/ui';

import { CurrentRateEditor } from '../CurrentRateEditor';
import { ExploreLinkButton } from '../ExploreLinkButton';
import { RecommendationBreakDown } from '../RecommendationBreakDown';
import { TablePatternRecommendation } from './types';
import { useFilter, useUserPermissions } from '@/hooks/context-hooks';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    columnHeaderUpperText: css({
      fontSize: theme.typography.bodySmall.fontSize,
      position: 'absolute',
      top: theme.spacing(-1.5),
    }),
    getLockIconStyle: (locked: boolean) => css({
        color: locked ? 'rgba(194, 109, 49, 1)' : undefined,
        marginTop: theme.spacing(0.4),
      }),
    multiLineHeader: css({
      display: 'inline-block',
      position: 'relative',
    }),
    noRows: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
      margin: `${theme.spacing(2)} auto 0`,
    }),
    patternCell: css({
      textAlign: 'left',
      wordBreak: 'break-word',
    }),
    readOnlyCurrentRate: css({
      width: '25%',
    }),
    readOnlyCurrentRateWrapper: css({
      display: 'flex',
      gap: 10,
    }),
    tableCell: css({
      'td[role="cell"]': {
        verticalAlign: 'top',
      },
    }),
  };
};
interface Props {
  tableData: TablePatternRecommendation[];
}

export function RecommendationsTable({ tableData }: Props) {
  const styles = useStyles2(getStyles);
  const { filterCheck, isFiltered } = useFilter();
  const userPermissions = useUserPermissions();

  const [memoizedTableData, setMemoizedTableData] = useState<TablePatternRecommendation[]>([]);

  const headerTooltips: ComponentProps<typeof InteractiveTable>['headerTooltips'] = {
    configured_drop_rate: {
      content: 'Percentage of log lines currently being dropped',
      iconName: 'info-circle',
    },
    drop_rate_comparison: {
      content: 'Comparison between recommended drop rate and current (or modified) drop rate.',
      iconName: 'arrows-h',
    },
    queried_lines: {
      content: 'Total number of log lines returned by all queries which match this pattern.',
      iconName: 'info-circle',
    },
    recommended_drop_rate: {
      content: 'Recommended percent of log lines to drop',
      iconName: 'info-circle',
    },
    savings: {
      content: '15 day projected savings using configured rate.',
      iconName: 'info-circle',
    },
    volume: {
      content: 'Ingested volume for past 15 days.',
      iconName: 'info-circle',
    },
  };

  const columns: Array<Column<TablePatternRecommendation>> = [
    {
      cell: ({ cell: { row } }: CellProps<TablePatternRecommendation, number>) => (
        <div>{row.original.recommended_drop_rate}%</div>
      ),
      header: 'Recommended',
      id: 'recommended_drop_rate',
      sortType: 'number',
    },
    {
      cell: ({ row }: CellProps<TablePatternRecommendation, number>) => {
        const recommendation: TablePatternRecommendation = row.original;
        const { locked } = recommendation;
        return userPermissions.canApplyPatterns ? (
          <CurrentRateEditor recommendation={recommendation} />
        ) : (
          <div className={styles.readOnlyCurrentRateWrapper}>
            <div className={styles.readOnlyCurrentRate} data-testid="current-rate-text">
              {recommendation.configured_drop_rate}%
            </div>
            <Icon
              data-testid="curren-rate-icon"
              className={styles.getLockIconStyle(locked)}
              name={locked ? 'lock' : 'unlock'}
            />
          </div>
        );
      },
      header: 'Current (%)',
      id: 'configured_drop_rate',
      sortType: 'number',
    },
    {
      cell: ({ cell: { row } }: CellProps<TablePatternRecommendation, number>) => {
        return <div>{fileSize(row.original.savings, 1)}</div>;
      },
      header: 'Savings',
      id: 'savings',
      sortType: 'number',
    },
    {
      cell: ({ cell: { row } }: CellProps<TablePatternRecommendation, number>) => {
        return <div>{fileSize(row.original.volume, 1)}</div>;
      },
      header: 'Volume',
      id: 'volume',
      sortType: 'number',
    },
    {
      cell: (cell: CellProps<TablePatternRecommendation, number>) => {
        return <div>{formatNumber((cell.row.original.queried_lines / cell.row.original.ingested_lines) * 100, 2)}</div>;
      },
      header: 'Query : Ingest',
      id: 'queried_lines',
      sortType: 'number',
    },
    {
      cell: ({ cell: { row } }: CellProps<TablePatternRecommendation, string>) => (
        <div className={styles.patternCell}>{row.original.pattern}</div>
      ),
      header: 'Pattern',
      id: 'pattern',
      sortType: 'string',
    },
    {
      cell: ({ row }: CellProps<TablePatternRecommendation>) =>
        userPermissions.canAccessExplore && <ExploreLinkButton recommendation={row.original} />,
      id: 'exploreLink',
    },
  ];

  // When filter updates, triggers the uFuzzy
  const filteredData = useMemo(() => {
    if (filterCheck === null) {
      return tableData;
    } else {
      return tableData.filter((item) => filterCheck.has(item.pattern));
    }
  }, [tableData, filterCheck]);

  useEffect(() => {
    // By doing a deep compare of the filteredData and memoizedTableData, we avoid the table re-rendering sending the
    // user back to the first page when selecting an item (or anything that would cause the filteredData useMemo to fire.
    if (!_isEqual(memoizedTableData, filteredData)) {
      setMemoizedTableData(filteredData);
    }
  }, [filteredData]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderExpandedRow = (recommendation: TablePatternRecommendation) => {
    return <RecommendationBreakDown recommendation={recommendation} />;
  };

  const noDataMessage = `No recommended patterns available ${isFiltered ? 'for the current filter' : ''}`;

  return (
    <>
      <InteractiveTable
        className={styles.tableCell}
        pageSize={25}
        columns={columns}
        data={memoizedTableData}
        headerTooltips={headerTooltips}
        renderExpandedRow={renderExpandedRow}
        getRowId={(rec: TablePatternRecommendation) => rec.pattern}
      />
      {!filteredData.length && (
        <div className={styles.noRows} data-testid="no-data-message">
          {noDataMessage}
        </div>
      )}
    </>
  );
}

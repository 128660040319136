import { FlagsmithClientProvider } from '@openfeature/flagsmith-client-provider';
import { QueryClient } from '@tanstack/react-query';
import { includes as _includes } from 'lodash';

import { config } from '@grafana/runtime';

import { FLAGSMITH_DEV_CLIENT_KEY, FLAGSMITH_PROD_CLIENT_KEY, paths } from '@/utils/constants';

const THREE_HOURS = 1000 * 60 * 60 * 3;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      retryOnMount: false,
      staleTime: THREE_HOURS,
    },
  },
});

export const isDev = () => {
  const isDevUrl = config.appUrl === 'http://localhost:3000' || _includes(config.appUrl, 'grafana-dev.net');
  return process.env.NODE_ENV === 'development' || isDevUrl;
};

export const flagsmithProvider = () =>
  new FlagsmithClientProvider({
    api: paths.flagsApi,
    environmentID: isDev() ? FLAGSMITH_DEV_CLIENT_KEY : FLAGSMITH_PROD_CLIENT_KEY,
  });

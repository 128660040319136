import { Metrics, PatternRecommendation } from '@/api/types';

export function getSummaryVolumes(recommendations: PatternRecommendation[]) {
  let relativeTotalVolume = 0;
  let relativeAfterDrop = 0;

  recommendations.forEach((r) => {
    // relative total
    relativeTotalVolume += r.volume;

    // relative drop value
    relativeAfterDrop += r.volume * ((100 - r.configured_drop_rate) / 100);
  });

  return [relativeTotalVolume, relativeAfterDrop];
}

export const getAbsoluteTotalVolume = (metrics?: Metrics) => {
  if (!metrics) {
    return null;
  } else {
    return metrics.ingested_volume + metrics.dropped_volume;
  }
};

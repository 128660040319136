import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { useStyles2 } from '@grafana/ui';

import { PageHeader } from '@/components/PageHeader';
import { FilterField } from '@/components/PageHeader/FilterField';
import { QueryResultHeader } from '@/components/QueryResultHeader';
import { RecommendationsTable } from '@/components/RecommendationsTable';
import { TablePatternRecommendation } from '@/components/RecommendationsTable/types';
import { useRecommendations } from '@/hooks/api-hooks';

const getStyles = () => ({
  tableContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
});

export const Recommendations = () => {
  const styles = useStyles2(getStyles);

  const {
    data: recommendations,
    error: recommendationsError,
    isError: recommendationsIsError,
    isLoading: recommendationsIsLoading,
  } = useRecommendations();

  const tableData: TablePatternRecommendation[] = useMemo(() => {
    if (!recommendations?.items) {
      return [];
    }

    return recommendations.items.map((r) => {
      const row: TablePatternRecommendation = {
        ...r,
        savings: r.configured_drop_rate > 0 ? r.volume * (r.configured_drop_rate / 100) : 0,
      };
      return row;
    });
  }, [recommendations?.items]);

  if (recommendationsIsLoading || !recommendations) {
    return (
      <QueryResultHeader
        errors={[recommendationsError]}
        isErrorArr={[recommendationsIsError]}
        isLoadingArr={[recommendationsIsLoading]}
      />
    );
  }

  return (
    <div>
      <PageHeader />
      <FilterField />
      <div className={styles.tableContainer}>
        <RecommendationsTable tableData={tableData} />
      </div>
    </div>
  );
};

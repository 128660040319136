import React, { useState } from 'react';

import { css, cx } from '@emotion/css';
import { debounce as _debounce } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Icon, Input, useStyles2 } from '@grafana/ui';

import { useFilter } from '@/hooks/context-hooks';
import { useRecommendationFilterCountMessage } from '@/hooks/util-hooks';

export const FilterField = ({ className, placeholder }: { className?: string; placeholder?: string }) => {
  const { setFilter } = useFilter();
  const [inputDisplay, setInputDisplay] = useState('');

  // Debounce to wait until 0.3s until users stop typing before updating filter
  const debouncedOnChange = _debounce((newFilter) => {
    setFilter(newFilter);
  }, 300);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newFilter = event.currentTarget.value;
    setInputDisplay(newFilter);
    debouncedOnChange(newFilter);
  };

  const styles = useStyles2(getStyles);
  const filterMessage = useRecommendationFilterCountMessage();

  return (
    <Field
      className={cx(
        css({
          margin: '8px 0 16px 0',
        }),
        className
      )}
    >
      <div className={styles.fieldContainer}>
        <Input
          data-testid="pattern-filter"
          prefix={<Icon name="search" />}
          placeholder={placeholder || 'Filter Pattern'}
          value={inputDisplay}
          onChange={onChange}
        />
        <div className={styles.message} data-testid="filter-count">
          {filterMessage}
        </div>
      </div>
    </Field>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    fieldContainer: css({ position: 'relative' }),
    message: css({
      fontSize: theme.typography.bodySmall.fontSize,
      pointerEvents: 'none',
      position: 'absolute',
      right: theme.spacing(1),
      textAlign: 'right',
      top: theme.spacing(1),
      width: theme.spacing(30),
      zIndex: 1,
    }),
  };
}

import { kebabCase as _kebabCase } from 'lodash';

import { PageDefinition } from '@/types';

export const pageDefinitionToRoute = (page: PageDefinition) => _kebabCase(page.id);

export const buildLogQueryFromPattern = (pattern: string, serviceNames: string[]) => {
  let result = '';

  if (!pattern || !serviceNames.length) {
    return '';
  }

  const patternQL = pattern.replaceAll(/<[A-Z*.]+>/g, '<_>');

  const serviceNameQL = serviceNames
    .map((name) => name.substring(name.indexOf('=') + 2, name.indexOf('}') - 1))
    .join('|');

  result = '{service_name=~"' + `${serviceNameQL}` + '"} |> `' + patternQL + '`';

  return result;
};

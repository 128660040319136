import { castArray as _castArray } from 'lodash';
import { lastValueFrom } from 'rxjs';

import { getBackendSrv } from '@grafana/runtime';

import { ListResponseWithHeaders, PatternRecommendation, Summary } from './types';
import { FLAGSMITH_DEV_CLIENT_KEY, FLAGSMITH_PROD_CLIENT_KEY, paths } from '@/utils/constants';
import { isDev } from '@/utils/state';

export async function getSummary<T>(recommendations: PatternRecommendation[]) {
  let summary = await getBackendSrv().post(paths.summary, recommendations, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return summary as Summary;
}

export async function getWithMappedItems<Key, T>(
  url: string,
  getKey: (item: T) => Key,
  params?: Record<string, unknown>
): Promise<ListResponseWithHeaders<Key, T>> {
  const { data } = await lastValueFrom(getBackendSrv().fetch<T>({ method: 'GET', params, showErrorAlert: false, url }));

  const allItems = _castArray(data);
  const mappedItems = new Map<Key, T>();
  const duplicateItems: T[] = [];

  const items = allItems.filter((item) => {
    // Filter to unique items while we build the map, and collect duplicates
    const key = getKey(item);
    if (mappedItems.has(key)) {
      duplicateItems.push(item);
      // We don't keep duplicates
      return false;
    } else {
      // We map the first entry, and ignore the rest
      mappedItems.set(key, item);
      // We only filter to keep the first value for a unique key
      return true;
    }
  });

  return {
    duplicateItems,
    items,
    mappedItems,
  };
}

export const updateFlagsmithIdentity = async (identifier: string, _traits: { [k: string]: unknown }) => {
  const traits = Object.entries(_traits).map(([key, value]) => ({
    trait_key: key,
    trait_value: value,
  }));
  await getBackendSrv().post(
    paths.flagsIdentityUpdate,
    { identifier, traits },
    {
      headers: {
        'x-environment-key': isDev() ? FLAGSMITH_DEV_CLIENT_KEY : FLAGSMITH_PROD_CLIENT_KEY,
      },
      showErrorAlert: false,
      showSuccessAlert: false,
    }
  );
};

// This was removed from `getWithMappedItems` and replaced by a parameter to allow the caller to determine how to get the key for an item type
export const DEFAULT_GET_KEY = (obj: unknown) => JSON.stringify(obj);

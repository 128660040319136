import uFuzzy from '@leeoniya/ufuzzy';
import {
  debounce as _debounce,
  flatten as _flatten,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  toLower as _toLower,
  uniq as _uniq,
} from 'lodash';

import { PatternRecommendation } from '@/api/types';

const uf = new uFuzzy({
  intraDel: 1,
  intraIns: 1,
  intraMode: 1,
  intraSub: 1,
  intraTrn: 1,
});

/** Returns a list of accepted indices */
export function filterRecommendations(
  recommendations?: PatternRecommendation[],
  filter?: string,
  serviceNameFilterSet?: Set<string>
): number[] | null {
  const isFilterEmpty = filter == null || _isEmpty(filter);
  const isServiceNameFilterEmpty = !serviceNameFilterSet || serviceNameFilterSet.size === 0;

  let acceptedIndices: number[] | null = null;

  if (!recommendations) {
    return null;
  }

  if (!isServiceNameFilterEmpty) {
    acceptedIndices = [];
    for (let index = 0; index < recommendations.length; index++) {
      const attributions = recommendations[index].attribution;
      /**
       * the attribution is of type
       * {service_name="xyz"} : {Volume: 1, Count: 1}
       * The names variable takes the substring of "service_name="xyz""
       * and return xyz
       *  */
      const names = _flatten(Object.keys(attributions)).map((name) => {
        return name.substring(name.indexOf('"') + 1, name.lastIndexOf('"'));
      });

      if (names.some((name) => serviceNameFilterSet.has(name))) {
        acceptedIndices.push(index);
      }
    }
  }

  if (!isFilterEmpty) {
    const useSimpleFilter = hasSymbols(filter) || isTooLong(filter);
    const patterns = recommendations.map((rec) => rec.pattern);

    if (useSimpleFilter) {
      acceptedIndices = simplerFilter(patterns, filter, acceptedIndices);
    } else {
      acceptedIndices = uf.filter(patterns, filter, acceptedIndices || undefined);
    }
  }

  return acceptedIndices;
}

const SYMBOLS = /["'<>=\[\]{}\*]/;

function hasSymbols(filter: string) {
  const result = SYMBOLS.test(filter);
  return result;
}

function isTooLong(filter: string) {
  return filter.length > FILTER_LENGTH_THRESHOLD;
}

/** The maximum filter length that we will allow uFuzzy to use.
 *
 *  Larger filter lengths will use `simplerFilter` instead
 */
export const FILTER_LENGTH_THRESHOLD = 25;

function simplerFilter(patterns: string[], filter: string, acceptedIndices: number[] | null) {
  const output: number[] = [];

  // Reduce the filter string into a list of terms that are:
  const terms = _uniq(
    // Unique
    filter
      .split(/\s/) // White-space separated
      .map(_toLower) // Lower-case
  ).filter((term) => term.length > 0); // Non-empty

  function test(index: number) {
    const pattern = _toLower(patterns[index]);
    if (terms.every((term) => pattern.includes(term))) {
      output.push(index);
    }
  }

  if (acceptedIndices === null) {
    for (let i = 0; i < patterns.length; ++i) {
      test(i);
    }
  } else {
    for (let i of acceptedIndices) {
      test(i);
    }
  }
  return output;
}

import React from 'react';

import { ConfirmModal } from '@grafana/ui';

import { ConfirmModalLoader } from '../ConfirmationModalLoader';
import { useModifiedDropRates } from '@/hooks/context-hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  recommendationsIsFetching: boolean;
}

export function ApplyModal({ isOpen, onClose, recommendationsIsFetching }: Props) {
  const { applyDropRateModifications, recommendationsUpdating } = useModifiedDropRates();

  const getConfirmButtonText = (isLoading?: boolean) => {
    return isLoading ? ((<ConfirmModalLoader text="Applying..." />) as unknown as string) : 'Apply';
  };

  const busy = recommendationsUpdating || recommendationsIsFetching;

  const noop = () => {
    return;
  };

  return (
    <ConfirmModal
      title="Apply drop rates"
      isOpen={isOpen}
      onDismiss={busy ? noop : onClose}
      confirmText={getConfirmButtonText(recommendationsIsFetching || recommendationsUpdating)}
      dismissText="Cancel"
      onConfirm={async () => {
        await applyDropRateModifications();
        onClose();
      }}
      body="Applying the current drop rates will reduce the volume of logs and may impact future queries. Are you sure you want to apply changes?"
    />
  );
}

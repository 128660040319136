import React, { Suspense } from 'react';

import { SkeletonTheme } from 'react-loading-skeleton';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AppRootProps } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';

import { AppPluginSettings } from '../AppConfig';
import { pages } from './PageDefinitions';
import { ContextProviders } from '@/components/ContextProvider';
import PageWrapper, { PageWrapperSkeleton } from '@/components/PageWrapper';
import { paths } from '@/utils/constants';
import { pageDefinitionToRoute } from '@/utils/methods';

export function App(props: AppRootProps<AppPluginSettings>) {
  const theme = useTheme2();
  return (
    <SkeletonTheme
      baseColor={theme.colors.background.secondary}
      highlightColor={theme.colors.emphasize(theme.colors.background.secondary)}
      borderRadius={theme.shape.radius.default}
    >
      <ContextProviders props={props}>
        <Suspense fallback={<PageWrapperSkeleton />}>
          <PageWrapper logo={props.meta.info.logos.large}>
            <Routes>
              {Array.from(pages.values()).map((pageDefinition) => {
                const { component: Component, id } = pageDefinition;
                return <Route key={id} path={`/${pageDefinitionToRoute(pageDefinition)}`} element={<Component />} />;
              })}
              <Route key="root" path={'*'} element={<Navigate to={`${paths.appRoot}/recommendations`} />} />
            </Routes>
          </PageWrapper>
        </Suspense>
      </ContextProviders>
    </SkeletonTheme>
  );
}
